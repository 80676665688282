<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center mb-1">
        <div class="mb-4 md:mb-0 mr-4">
          <vs-button v-if="!selectable && $can('create_customer')" color="primary" type="border" icon-pack="feather" icon="icon-plus" class="px-3" @click="modalAddActive = true">Tambah</vs-button>
        </div>

        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <!--limit chooser-->
          <PagingLimit :currentPage="table.page" :limit="table.limit" :total="table.totalItems" @changeLimit="onPageLimitChange"/>

          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 w-full" placeholder="Cari" v-model="table.search" @keyup="onSearch"/>

            <!--actions-->
            <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
              <vs-button class="mb-4 md:mb-0 px-3 py-3 flex items-center justify-center" type="filled" icon-pack="feather" icon="icon-more-vertical"/>
              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <ExportCsv v-if="$refs.mainTable" :data="$refs.mainTable._props.data" />
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>

      <!--loading-->
      <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

      <!--table-->
      <vs-table
        ref="mainTable"
        :class="{ 'animate-pulse': table.loading }"
        :sst="true"
        :data="table.rows"
        @sort="onSort"
        stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap" v-if="selectable"></vs-th>
          <vs-th class="whitespace-no-wrap" v-if="!selectable">Aksi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="status_customer">Status Customer</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama">Nama</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="id_proyek">Proyek</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="id_profesi">Profesi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="kode">Kode</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="no_identitas">No.Identitas</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="gender">Gender</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="tmp_lahir">Tempat Lahir</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="tgl_lahir">Tanggal Lahir</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="domisili">Domisili</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="alamat">Alamat</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="kelurahan">Kelurahan</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="kota">Kota</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="pos">Pos</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="telp">Telp</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="hp">Hp</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="email">E-mail</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="agama">Agama</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="status_profesi =">Status Profesi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="npwp">NPWP</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="alamat_npwp">Alamat NPWP</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="alamat_surat">Alamat Surat</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="no_rekening">No.Rekening</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="jabatan">Jabatan</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_instansi">Nama Instansi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="telp_instansi">Telp Instansi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="fax_instansi">Fax Instansi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="alamat_instansi">Alamat Instansi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="cp_instansi">CP Instansi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="jenis_usaha">Jenis Usaha</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="sumber_dana">Sumber Dana</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="tujuan_pembelian">Tujuan Pembelian</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="status_nikah">Status Nikah</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_pasangan">Nama Pasangan</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="status_tmptgl">Status Tempat Tinggal</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="pengh_tetap">Penghasilan Tetap</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="pengh_tambah">Penghasilan Tambahan</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="pengh_bulanan">Penghasilan Bulanan</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="created_at">Source</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="created_at">Created At</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td class="whitespace-no-wrap" v-if="selectable">
              <vs-button class="py-2 px-3" type="border" @click="onSelected(item)">Pilih</vs-button>
            </vs-td>
            <vs-td class="whitespace-no-wrap" v-if="!selectable">
              <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer" v-if="$canAny(['update_customer', 'delete_customer'])">
                <vs-button class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                <vs-dropdown-menu>
                  <vs-dropdown-item class="p-1" @click="showModalEdit(item)" v-if="$can('update_customer')"><span class="whitespace-no-wrap">Edit</span></vs-dropdown-item>
                  <vs-dropdown-item class="p-1" @click="showModalDetail(item)"><span class="whitespace-no-wrap">Detail</span></vs-dropdown-item>
                  <vs-dropdown-item class="p-1" @click="confirmDelete(item.id)" v-if="$can('delete_customer')"><span class="whitespace-no-wrap text-danger">Hapus</span></vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.status_customer">
              <span class="inline-flex items-center whitespace-no-wrap justify-center p-2 text-xs leading-none text-white bg-red rounded-full" :class="['bg-' + getColorOfStatus(item.status_customer)]">{{ item.status_customer }}</span>
            </vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.nama">{{ item.nama }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.nama_proyek">{{ item.nama_proyek }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.nama_profesi">{{ item.nama_profesi }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.kode">{{ item.kode }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.no_identitas">{{ item.no_identitas }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.gender">{{ item.gender }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.tmp_lahir">{{ item.tmp_lahir }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.tgl_lahir">{{ item.tgl_lahir }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.domisili">{{ item.domisili }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.alamat">{{ item.alamat }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.kelurahan">{{ item.kelurahan }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.kota">{{ item.kota }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.pos">{{ item.pos }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.telp">{{ item.telp }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.hp">{{ item.hp }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.email">{{ item.email }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.agama">{{ item.agama }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.status_profesi">{{ item.status_profesi }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.npwp">{{ item.npwp }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.alamat_npwp">{{ item.alamat_npwp }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.alamat_surat">{{ item.alamat_surat }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.no_rekening">{{ item.no_rekening }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.jabatan">{{ item.jabatan }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.nama_instansi">{{ item.nama_instansi }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.telp_instansi">{{ item.telp_instansi }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.fax_instansi">{{ item.fax_instansi }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.alamat_instansi">{{ item.alamat_instansi }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.cp_instansi">{{ item.cp_instansi }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.jenis_usaha">{{ item.jenis_usaha }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.sumber_dana">{{ item.sumber_dana }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.tujuan_pembelian">{{ item.tujuan_pembelian }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.status_nikah">{{ item.status_nikah }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.nama_pasangan">{{ item.nama_pasangan }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.status_tmptgl">{{ item.status_tmptgl }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.pengh_tetap">{{ item.pengh_tetap }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.pengh_tambah">{{ item.pengh_tambah }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.pengh_bulanan">{{ item.pengh_bulanan }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.tipe_customer">{{ item.tipe_customer }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.created_at">{{ item.created_at }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <!--paging-->
      <div>
        <vs-pagination class="text-sm mt-6 overflow-x-scroll hidden sm:block" v-model="table.page" @change="onPageChange" :total="totalPages"/>
        <div class="flex w-full justify-end mt-6 justify-around sm:hidden">
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" @click="table.page > 1 && table.page--; onPageChange"/>
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" @click="table.page < totalPages && table.page++; onPageChange"/>
        </div>
      </div>

    </vx-card>

    <!--modals-->
    <CustomerAdd v-if="!selectable" :isActive.sync="modalAddActive" @success="getData"/>
    <CustomerEdit v-if="!selectable" :isActive.sync="modalEditActive" @success="getData" :item="modalEditItem"/>
    <CustomerDetail v-if="!selectable" :isActive.sync="modalDetailActive" @success="getData" :item="modalDetailItem"/>
  </div>
</template>

<script>
import CustomerRepository from '@/repositories/master/customer-repository'
import PagingLimit from '@/views/components/paging-limit/PagingLimit'
import _ from 'lodash'

export default {
  name: 'Customer',
  components: {
    CustomerAdd: () => import('@/views/pages/master/customer/CustomerAdd'),
    CustomerEdit: () => import('@/views/pages/master/customer/CustomerEdit'),
    CustomerDetail: () => import('@/views/pages/master/customer/CustomerDetail'),
    ExportCsv: () => import('@/views/components/export-csv/ExportCsv'),
    PagingLimit
  },
  props: {
    selectable: { default: false, type: Boolean },
    externalFilter: { default: null, type: Object }
  },
  watch: {
    externalFilter (newVal, oldVal) {
      if (newVal && !_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.getData()
      }
    }
  },
  data () {
    return {
      modalAddActive: false,
      modalEditActive: false,
      modalDetailActive: false,
      modalEditItem: {},
      modalDetailItem: {},
      table: {
        loading: false,
        page: 1,
        limit: 10,
        totalItems: 0,
        sort: '',
        search: '',
        rows: []
      }
    }
  },
  computed: {
    statusIndicator () {
      return [
        /* NEW, COLD, WARM, HOT, FOLLOW UP/CALL 1, FOLLOW UP/CALL 2, FOLLOW UP/CALL 3, CLOSING DEAL, CANCEL) */
        {
          status: 'NEW',
          color: 'primary'
        },
        {
          status: 'COLD',
          color: 'blue'
        },
        {
          status: 'WARM',
          color: 'orange'
        },
        {
          status: 'HOT',
          color: 'red'
        },
        {
          status: 'FOLLOW UP/CALL 1',
          color: 'cyan'
        },
        {
          status: 'FOLLOW UP/CALL 2',
          color: 'cyan'
        },
        {
          status: 'FOLLOW UP/CALL 3',
          color: 'cyan'
        },
        {
          status: 'CLOSING DEAL',
          color: 'green'
        },
        {
          status: 'CANCEL',
          color: 'grey-dark'
        }
      ]
    },
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    }
  },
  methods: {
    getData () {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)

      if (this.externalFilter) {
        params.externalFilter = JSON.stringify(this.externalFilter)
      }

      CustomerRepository.get(params)
        .then(response => {
          this.table.rows = response.data.data.data
          this.table.totalItems = response.data.data.total
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    delete (id) {
      CustomerRepository.delete(id)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    showModalEdit (item) {
      this.modalEditItem = item
      this.modalEditActive = true
    },
    showModalDetail (item) {
      this.modalDetailItem = item
      this.modalDetailActive = true
    },

    onPageChange () {
      this.getData()
    },

    onPageLimitChange (limit) {
      this.table.limit = limit
      if (this.table.page > this.totalPages) {
        this.table.page = this.totalPages
      }
      this.getData()
    },

    onSelected (item) {
      const itemCopy = Object.assign({}, item)
      this.$emit('selected', itemCopy)
    },

    onSort (key, order) {
      this.table.sort = (key === null || order === null) ? '' : `${key} ${order}`
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500),

    getColorOfStatus (status) {
      const statusIndicator = _.find(this.statusIndicator, (item) => {
        return item.status.toLowerCase() === status.toLowerCase()
      })
      return statusIndicator != null ? statusIndicator.color : 'black'
    }
  }
}
</script>
